<form (ngSubmit)="resetPassword(resetPasswordForm)" #resetPasswordForm="ngForm" class="forget-form" novalidate>
    <div class="form-title">{{ 'hollen.clientapp.src.app.auth.reset-password.component.change password' | translate }}</div>
    <div *ngIf="!resetPasswordForm.valid && resetPasswordForm.submitted">
        <div class="alert alert-danger" *ngIf="resetPasswordForm.controls['email']?.hasError('required')">
            <span>
                {{ 'hollen.clientapp.src.app.auth.reset-password.component.enter your email.' | translate }}
            </span>
        </div>
        <div class="alert alert-danger" *ngIf="!resetPasswordForm.controls['email']?.hasError('required') && resetPasswordForm.controls['email']?.hasError('email')">
            <span>
                {{ 'hollen.clientapp.src.app.auth.reset-password.component.incorrect email format.' | translate }}
            </span>
        </div>
        <div class="alert alert-danger" *ngIf="resetPasswordForm.controls['password']?.hasError('required')">
            <span>
                {{ 'hollen.clientapp.src.app.auth.reset-password.component.enter your password.' | translate }}
            </span>
        </div>
    </div>
   <div class="alert alert-danger" *ngIf="password.value !== confirmPassword.value">
        <span>
            {{ 'hollen.clientapp.src.app.auth.reset-password.component.password not match.' | translate }}
        </span>
    </div>
    <div class="mb-3">
        <input class="form-control placeholder-no-fix" #email="ngModel" validateEmail type="email" autocomplete="off" placeholder="{{ 'hollen.clientapp.src.app.auth.reset-password.component.e-mail' | translate }}" name="email" [(ngModel)]="resetPasswordData.email" required />
    </div>
    <div class="mb-3">
        <input class="form-control placeholder-no-fix" #password="ngModel" type="password" autocomplete="off" placeholder="{{ 'hollen.clientapp.src.app.auth.reset-password.component.password' | translate }}" name="password" [(ngModel)]="resetPasswordData.password" required />
    </div>
    <div class="mb-3">
        <input class="form-control placeholder-no-fix" #confirmPassword="ngModel" type="password" autocomplete="off" placeholder="{{ 'hollen.clientapp.src.app.auth.reset-password.component.repeat password' | translate }}" name="confirmPassword" [(ngModel)]="resetPasswordData.confirmPassword" required />
    </div>
    <div class="form-actions">
        <button type="button" id="back-btn" class="btn btn-default" routerLink="/login">{{ 'hollen.clientapp.src.app.auth.reset-password.component.back' | translate }}</button>
        <button [disabled]="isLoading" type="submit" class="btn btn-primary uppercase float-end" (dblclick)="0">{{ 'hollen.clientapp.src.app.auth.reset-password.component.change' | translate }}</button>
    </div>
</form>
