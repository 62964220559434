import { Component } from "@angular/core";

@Component({
  selector: "af-not-found",
  template: `
    <div>
      <h4>{{ 'not-found.inconceivable' | translate }}</h4>
      <div>{{ 'not-found.message' | translate }}</div>
    </div>
  `
})
export class PageNotFoundComponent { }
