
import { throwError as observableThrowError, from as observableFrom, Observable } from "rxjs";
import { catchError, mergeMap, tap, map, finalize } from "rxjs/operators";
import { Injectable, Injector } from "@angular/core";
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpResponse, HttpParams } from "@angular/common/http";
import { StorageService } from "../../auth/storage.service";
import { AuthConfig } from "../../auth/auth.config";
import { AuthService, LoginResponse } from "../../auth/auth.service";
import { LoadingBarService } from "../../shared/components/loading-bar/loading-bar.service";
import { VersionService } from "./version.service";
import { ToastService } from "./toast.service";
import { AppService } from "../../app.service";
import { TranslateService } from "@ngx-translate/core";
import { convertDateStringsToDates, CustomQueryEncoderHelper } from "../utils";
import { environment } from '../../../environments/environment';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    constructor(
        private injector: Injector,
        private authConfig: AuthConfig,
        private versionService: VersionService,
        private toastService: ToastService,
        private loadingService: LoadingBarService
    ) { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        let authService = this.injector.get(AuthService);
        let translateService = this.injector.get(TranslateService);
        // Get the auth header from the service.
        let token = authService.getToken();
        // Clone the request to add the new header.
        let responseType = req.responseType;
        if (req.responseType === "json") {
            responseType = "text";
        }

        let acceptHeader = "application/json, text/plain, */*";

        if (!environment.production) {
            acceptHeader = "application/json, text/html, text/plain, */*"; //to show nice error sites in development
        }

        let authHeaders = req.headers.set("Accept", acceptHeader);
        
        if(token) {
            authHeaders = authHeaders.set(this.authConfig.headerName, this.authConfig.headerPrefix + token);
        }

        // Pass on the cloned request instead of the original request.
        let authReq = req.clone({
            responseType,
            headers: authHeaders
        });

        let showToast = true;
        if (req.params.has("showToast")) {
            showToast = req.params.get("showToast") === "true";
            let params = req.params.delete("showToast");
            authReq = authReq.clone({ params });
        }

        let allowAnonymous = false;
        if (req.params.has("allowAnonymous")) {
            allowAnonymous = req.params.get("allowAnonymous") === "true";
            let params = req.params.delete("allowAnonymous");
            authReq = authReq.clone({ params });
        }

        let showLoadingBar = true;
        if (req.params.has("showLoadingBar")) {
            showLoadingBar = req.params.get("showLoadingBar") === "true";
            let params = req.params.delete("showLoadingBar");
            authReq = authReq.clone({ params });
        }

        let newParams = new HttpParams({ encoder: new CustomQueryEncoderHelper(), fromString: authReq.params.toString() });

        authReq = authReq.clone({ params: newParams });

        return <any>authService
            .refreshTokens(false, allowAnonymous).pipe(
                catchError(error => {
                    return this.onUnauthorized(authService);
                }),
                mergeMap((data: LoginResponse) => {
                    token = authService.getToken();
                    if ((!token && !allowAnonymous) || data.isError) {
                        return this.onUnauthorized(authService);
                    }
                    if (showLoadingBar) {
                        this.loadingService.start();
                    }
                    return next
                        .handle(authReq).pipe(
                            tap(event => {
                                if (event instanceof HttpResponse) {
                                    this.versionService.setVersion(event.headers.get("X-AF-Version"));
                                    if (req.method !== "GET" && event.ok && showToast) {
                                        this.toastService.success("OK!");
                                    }
                                }
                            }),
                            map(response => {
                                if (response instanceof HttpResponse) {
                                    if (req.responseType === "json" && response.body) {
                                        let newBody = JSON.parse(response.body);
                                        convertDateStringsToDates(newBody);
                                        response = response.clone<any>({ body: newBody });
                                    }
                                }
                                return response;
                            }),
                            finalize(() => {
                                if (showLoadingBar) {
                                    this.loadingService.complete();
                                }
                            }),
                            catchError(res => {
                                if (res.status === 401) {
                                    if (showLoadingBar) {
                                        this.loadingService.start();
                                    }
                                    return authService
                                        .refreshTokens(true).pipe(
                                            catchError(error => {
                                                return this.onUnauthorized(authService);
                                            }),
                                            mergeMap((data2: LoginResponse) => {
                                                if (showLoadingBar) {
                                                    this.loadingService.complete();
                                                }
                                                token = authService.getToken();
                                                if (!token || data2.isError) {
                                                    return this.onUnauthorized(authService);
                                                }
                                                let clonedRequestRepeat = req.clone({
                                                    responseType,
                                                    headers: req.headers.set(this.authConfig.headerName, this.authConfig.headerPrefix + token)
                                                });
                                                return next.handle(clonedRequestRepeat).pipe(map(response => {
                                                    if (response instanceof HttpResponse) {
                                                        if (req.responseType === "json" && response.body) {
                                                            let newBody = JSON.parse(response.body);
                                                            convertDateStringsToDates(newBody);
                                                            response = response.clone<any>({ body: newBody });
                                                        }
                                                    }
                                                    return response;
                                                }));
                                            }));
                                } else {
                                    return this.handleError(res, translateService, showToast);
                                }
                            }));
                }));
    }

    onUnauthorized(authService: AuthService) {
        authService.clear();

        return observableFrom<any>(
            new Promise((resolve, reject) => {
                // clear cookies
                let xhr = new XMLHttpRequest();
                xhr.open("POST", "/signout", true);
                xhr.send();

                xhr.onreadystatechange = () => {
                    window.location.reload();
                };
            })
        );
    }

    handleError(res: any, translateService: TranslateService, showToast?: boolean) {
        let result = {
            message: null,
            stack: null,
            name: res.status,
            status: res.status
        };

        if (res.headers && res.headers.get("Content-Type")) {
            if (res.headers.get("Content-Type").indexOf("text/html") !== -1 || res.headers.get("Content-Type").indexOf("text/plain") !== -1) {
                if (!environment.production) {
                    let win = window.open();
                    if (win && win.document) {
                        win.document.open();
                        win.document.write(res.error);
                        win.document.close();
                    }
                    else {
                        this.toastService.error(res.message);
                    }
                    return observableThrowError(result);
                }
            } else if (res.error && res.headers.get("Content-Type").indexOf("application/json") !== -1) {
                result = JSON.parse(res.error);
                if (!result.status) {
                    result.status = res.status;
                }
            }
        }

        if (res.status === 404) {
            result.message = translateService.instant("not found");
        } else if (res.status === 403) {
            if (!result.message) {
                result.message = translateService.instant("forbidden");
            }
        } else if (res.status === 400) {
            if (result["modelErrors"] && result["modelErrors"].length > 0) {
                if (showToast) {
                    for (let i = 0; i < result["modelErrors"].length; i++) {
                        let modelError = result["modelErrors"][i];
                        this.toastService.error(modelError.errorMessage);
                    }
                }
                return observableThrowError(result);
            }
        } else if (res.status >= 500) {
            if (res.status === 503) {
                showToast = false;
                result.message = translateService.instant("service-unavailable.message");
            } else if (result["detail"]) {
                result.message = " " + result["detail"];
            }
        } else {
            result.message = "Connection problem";
            result.stack = JSON.stringify(res);
        }

        if (showToast) {
            if (!result.message) {
                result.message = "Error";
            }
            this.toastService.error(result.message);
        }

        return observableThrowError(result);
    }
}
