
import { finalize } from "rxjs/operators";
import { Component, ViewChild, OnDestroy, OnInit } from "@angular/core";
import { NgForm } from "@angular/forms";
import { Router, ActivatedRoute } from "@angular/router";

import { FormComponent } from "../../core";
import { MenuService } from "../../core/services/menu.service";
import { AuthService } from "../../auth/auth.service";
import { MenuItem } from "../../models/menu-item";

@Component({
  selector: "acc-home-url",
  templateUrl: "./home-url.component.html"
})
export class HomeUrlComponent extends FormComponent implements OnInit {
  @ViewChild(NgForm) editSettingsForm: NgForm;
  menu: MenuItem[];
  settings = <app.usersystem.AccountSettingViewModel>{};
  selected: string;
  userNotExists = false;
  showMenu = false;

  constructor(protected router: Router, private authService: AuthService, private menuService: MenuService) {
    super(router);
  }

  ngOnInit() {
    let tempMenu = this.menuService.getMenu();
    this.menu = tempMenu.filter(x => x.type === "Menu");

    this.authService.getSettings(true).subscribe(
      result => {
        this.settings = result.model;
        this.selected = this.settings.homeUrl;
        this.isLoading = false;
        this.showMenu = true;
      },
      err => {
        this.userNotExists = true;
      }
    );
  }

  onChange(url: string) {
    this.selected = this.settings.homeUrl = url;
    this.editSettingsForm.control.markAsDirty();
  }

  canDeactivate() {
    return !this.editSettingsForm || this.editSettingsForm.pristine;
  }

  update(): void {
    if (this.editSettingsForm.invalid) {
      return;
    }

    this.isLoading = true;
    this.authService
      .updateSettings(this.settings).pipe(
        finalize(() => (this.isLoading = false)))
      .subscribe(result => {
        this.editSettingsForm.control.markAsPristine();
      });
  }
}
