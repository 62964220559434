import { NgModule, LOCALE_ID, ErrorHandler, APP_INITIALIZER } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { HTTP_INTERCEPTORS, HttpClient, provideHttpClient, withInterceptorsFromDi } from "@angular/common/http";
import { registerLocaleData } from "@angular/common";
import { TranslateModule, TranslateLoader, TranslateParser, MissingTranslationHandler } from "@ngx-translate/core";
import { StoreModule } from "@ngrx/store";
import { StoreDevtoolsModule } from "@ngrx/store-devtools";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import * as Sentry from "@sentry/angular";
import { Router } from "@angular/router";

import { reducers } from "./store/reducers";
import { AppService } from "./app.service";
import { AppRoutingModule } from "./app-routing.module";

import { AuthModule } from "./auth/auth.module";
import { AccountModule } from "./account/account.module";
import { CoreModule, createTranslateLoader, TextMissingTranslationHandler } from "./core";
import { SharedModule } from "./shared/shared.module";

import { AppComponent } from "./app.component";
import { PageNotFoundComponent } from "./page-not-found.component";

import localeSk from "@angular/common/locales/sk";
import { AuthInterceptor } from "./core/services/auth-interceptor.service";
import { DateInterceptor } from "./core/services/date-interceptor.service";
import { ngbTypeaheadConfigProvider } from "./shared/configs/ngbtypeahead.config";

registerLocaleData(localeSk);

export function getLanguage(appService: AppService) {
    return appService.languageId;
}

@NgModule({ declarations: [
        AppComponent,
        PageNotFoundComponent
    ],
    bootstrap: [AppComponent], imports: [BrowserModule,
        BrowserAnimationsModule,
        StoreModule.forRoot(reducers, {
            runtimeChecks: {
                strictStateImmutability: false,
                strictActionImmutability: false
            }
        }),
        StoreDevtoolsModule.instrument({
            maxAge: 25 //  Retains last 25 states
        }),
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: (createTranslateLoader),
                deps: [HttpClient]
            }
        }),
        CoreModule,
        SharedModule.forRoot(),
        AuthModule,
        AccountModule,
        AppRoutingModule], providers: [
        AppService,
        {
            provide: LOCALE_ID,
            useFactory: (getLanguage),
            deps: [AppService]
        },
        {
            provide: ErrorHandler,
            useValue: Sentry.createErrorHandler({
                showDialog: false,
            }),
        }, {
            provide: Sentry.TraceService,
            deps: [Router],
        },
        {
            provide: APP_INITIALIZER,
            useFactory: () => () => { },
            deps: [Sentry.TraceService],
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthInterceptor,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: DateInterceptor,
            multi: true
        },
        { provide: MissingTranslationHandler, useClass: TextMissingTranslationHandler },
        ngbTypeaheadConfigProvider,
        provideHttpClient(withInterceptorsFromDi())
    ] })
export class AppModule { }
