import { Injectable } from "@angular/core";
import { CanActivate, CanLoad, Router, Route, ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";

import { AuthService } from "./auth.service";

@Injectable()
export class AuthGuard implements CanActivate, CanLoad {
    constructor(private authService: AuthService, private router: Router) { }

    canLoad(route: Route) {
        return this.canContinue(`/${route.path}`);
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        return this.canContinue(state.url);
    }

    canContinue(url) {
        if (this.authService.isAuthenticated) {
            return true;
        }

        // Set our navigation extras object
        // that contains our global query params and fragment
        let navigationExtras = {
            queryParams: { returnUrl: url }
        };

        // Navigate to the login page with extras
        this.router.navigate(["/login"], navigationExtras);
        return false;
    }
}
