import { filter } from "rxjs/operators";
import { Component, OnInit } from "@angular/core";
import { Store } from "@ngrx/store";
import { State } from "../../store/reducers";

@Component({
    selector: "files",
    templateUrl: "./files.component.html"
})
export class FilesComponent implements OnInit {
    isLoading = true;
    employeeId: number;

    constructor(private store: Store<State>) {
    }

    ngOnInit() {
        this.store
            .select(x => x.app.homeViewModel).pipe(
                filter(loaded => !!loaded))
            .subscribe(model => {
                this.employeeId = model.user.employeeId;
                this.isLoading = false;
            });
    }
}
