<form class="forget-form" (ngSubmit)="send(forgotPasswordForm)" #forgotPasswordForm="ngForm" novalidate>
    <div class="form-title">{{ 'hollen.clientapp.src.app.auth.forgot-password.component.forgot password?' | translate }}</div>
    <div class="form-subtitle">{{ 'hollen.clientapp.src.app.auth.forgot-password.component.enter your e-mail to reset it.' | translate }}</div>
    <div class="alert alert-danger" *ngIf="!forgotPasswordForm.valid && forgotPasswordForm.submitted && !isSuccess">
        <span *ngIf="forgotPasswordForm.controls['email']?.hasError('required')">
            {{ 'hollen.clientapp.src.app.auth.forgot-password.component.enter your email.' | translate }}
        </span>
        <span *ngIf="!forgotPasswordForm.controls['email']?.hasError('required') && forgotPasswordForm['email']?.hasError('email')">
            {{ 'hollen.clientapp.src.app.auth.forgot-password.component.incorrect email format.' | translate }}
        </span>
    </div>
    <div [ngClass]="isError ? 'alert alert-danger' : 'alert alert-success'" *ngIf="isSuccess">
        {{message}} 
    </div>
    <div class="mb-3">
        <input #email="ngModel" validateEmail class="form-control placeholder-no-fix" type="email" autocomplete="off" placeholder="{{ 'hollen.clientapp.src.app.auth.forgot-password.component.e-mail' | translate }}" name="email" [(ngModel)]="forgotPasswordData.email" required />
    </div>
    <div class="form-actions">
        <button type="button" id="back-btn" class="btn btn-default" routerLink="/login">{{ 'hollen.clientapp.src.app.auth.forgot-password.component.back' | translate }}</button>
        <button [disabled]="isLoading" type="submit" class="btn btn-primary uppercase float-end" (dblclick)="0">{{ 'hollen.clientapp.src.app.auth.forgot-password.component.submit' | translate }}</button>
    </div>
</form>

