import { Injectable } from "@angular/core";
import { Router, Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { Observable, of } from "rxjs";
import { Store } from "@ngrx/store";

import { AuthService } from "./auth.service";
import { AppService } from "../app.service";
import { State } from "../store/reducers";
import * as appActions from "../store/actions/app";
import { take, switchMap, tap } from "rxjs/operators";

export interface IAuthResolver { }

@Injectable()
export class AuthResolver implements Resolve<IAuthResolver> {

    constructor(private authService: AuthService, private store: Store<State>, private appService: AppService, private router: Router) {
    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | any {
        return this.store.select(x => x.app.isAuthenticated)
            .pipe(
                take(1),
                switchMap(isAuthenticated => {
                    if (isAuthenticated) {
                        this.router.navigateByUrl("/");
                        return of();
                    }

                    return this.authService.getLogin().pipe(tap(res => {
                        this.store.dispatch(new appActions.LoginViewModelLoadedAction(res));
                        this.store.dispatch(new appActions.LanguageChangedAction(res.languageId));
                    }));
                }));
    }
}
