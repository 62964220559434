import { HttpInterceptor, HttpHandler, HttpEvent, HttpRequest, HttpResponse } from "@angular/common/http";
import { Observable } from "rxjs";
//import { convertDateStringsToDates } from "../utils";
import { Injectable } from "@angular/core";

@Injectable()
export class DateInterceptor implements HttpInterceptor {
  constructor() { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    //angular bug with json.parse null => temporarly handled in authinterceptor
    return next.handle(req);

    // return next.handle(req).map((event: any) => {
    //   if (event instanceof HttpResponse) {
    //     convertDateStringsToDates(event.body);
    //     const response = event.clone({ body: event.body });
    //     return response;
    //   }
    //   return event;
    // });
  }
}
