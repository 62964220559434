<af-loading-bar></af-loading-bar>
<af-page-header *ngIf="isAuthenticated"></af-page-header>
<div class="app-body" [ngClass]="{'app-login': !isAuthenticated}">
    <af-sidebar *ngIf="isAuthenticated"></af-sidebar>
    <div class="app-content">
        <div *ngIf="isNewVersion$ | async" class="alert alert-warning">
            <i class="fa fa-exclamation-triangle"></i> {{ 'hollen.clientapp.src.app.app.component.there is new version, please refresh browser' | translate }}
        </div>
        <router-outlet></router-outlet>
    </div>
</div>
<button (click)="topFunction()" class="fa fa-arrow-alt-circle-up" id="scrollTopButton" title="{{ 'hollen.clientapp.src.app.app.component.scroll to top' | translate }}">
</button>
<af-fixed-action-buttons *ngIf="isAuthenticated"></af-fixed-action-buttons>
