import { Component, ViewChild, OnInit } from "@angular/core";
import { NgForm } from "@angular/forms";
import { Router } from "@angular/router";

import { AuthService } from "../../auth/auth.service";
import { FormComponent, HomeResolver, ToastService } from "../../core";
import { finalize } from "rxjs/operators";

@Component({
    selector: "acc-settings",
    templateUrl: "./settings.component.html"
})
export class SettingsComponent extends FormComponent implements OnInit {
    @ViewChild(NgForm, { static: true }) editSettingsForm: NgForm;
    @ViewChild(NgForm, { static: true }) changePasswordForm: NgForm;
    settings = <app.usersystem.AccountSettingViewModel>{};
    languages: af.sys.LocalizationSelectViewModel[];
    changePasswordModel = <af.usersystem.ChangePasswordViewModel>{};
    message: string;
    imageError: string;

    constructor(protected router: Router, private authService: AuthService, private homeResolver: HomeResolver, private toastService: ToastService) {
        super(router);
    }

    ngOnInit() {
        this.isLoading = true;
        this.authService.getSettings(true).subscribe((result) => {
            this.settings = result.model;
            if (!this.settings.theme) this.settings.theme = 'light';
            this.languages = result.languages;
            this.isLoading = false;
        });
    }

    canDeactivate() {
        return (!this.editSettingsForm || this.editSettingsForm.pristine) && (!this.changePasswordForm || this.changePasswordForm.pristine);
    }

    update(): void {
        if (this.editSettingsForm.invalid) {
            return;
        }

        this.isLoading = true;
        this.authService.updateSettings(this.settings).subscribe(result => {
            this.editSettingsForm.control.markAsPristine();
            this.homeResolver
                .resolveForce()
                .pipe(finalize(() => this.isLoading = false))
                .subscribe();
        });
    }

    changePassword(): void {
        if (this.changePasswordForm.invalid) {
            return;
        }

        this.isLoading = true;
        this.authService.changePassword(this.changePasswordModel)
            .pipe(finalize(() => this.isLoading = false))
            .subscribe((response) => {
                this.changePasswordForm.control.markAsPristine();
                this.message = response.message;
            }, (response) => {
                this.message = response.message || "Error";
            });
    }

    fileChangeEvent(fileInput: any) {
        this.imageError = null;
        if (fileInput.target.files && fileInput.target.files[0]) {
            var kb = 200;
            var maxSize = kb * 1024; // 200kB
            if (fileInput.target.files[0].size > maxSize) {
                fileInput.target.value = '';  
                this.toastService.error(`Súbor je príliš veľký. Maximum je ${kb}kB`);
                return;
            }

            const reader = new FileReader();

            reader.onload = (e: any) => {
                const image = new Image();
                image.src = e.target.result;
                image.onload = rs => {
                    const imgBase64Path = e.target.result;
                    this.settings.signature = imgBase64Path;
                };
            };

            reader.readAsDataURL(fileInput.target.files[0]);
        }
        else {
            fileInput.target.value = '';
        }
    }

    removeImage() {
        this.settings.signature = null;
    }
}
