import { NgModule } from "@angular/core";
import { Routes, RouterModule, Router } from "@angular/router";

import { LoginComponent } from "./login.component";
import { AuthComponent } from "./auth.component";
import { ForgotPasswordComponent } from "./forgot-password.component";
import { ResetPasswordComponent } from "./reset-password.component";
import { AuthResolver } from "./auth-resolver.service";
import { HomeResolver } from "../core/services/home-resolver.service";
import { RedirectComponent } from "./redirect.component";
import { AuthGuard } from "./auth-guard.service";

const routes: Routes = [
    {
        path: "",
        pathMatch: "full",
        component: RedirectComponent,
        canActivate: [AuthGuard],
        resolve: { home: HomeResolver }
    },
    {
        path: "",
        component: AuthComponent,
        resolve: {
            login: AuthResolver
        },
        children: [
            {
                path: "login",
                component: LoginComponent
            },
            {
                path: "reset-password",
                component: ResetPasswordComponent
            },
            {
                path: "forgot-password",
                component: ForgotPasswordComponent
            }
        ]
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class AuthRoutingModule { }

export const routedComponents = [
    AuthComponent,
    LoginComponent,
    ResetPasswordComponent,
    ForgotPasswordComponent,
    RedirectComponent
];
