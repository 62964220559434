<div class="logo">
	<img src="/images/logo-intranet.png" alt="intranet logo" />
	<img src="/images/logo.png" alt="hint logo" />
</div>
<div class="content">
	<router-outlet></router-outlet>
</div>
<div class="copyright">
    &copy; {{ year }} - AlejTech s.r.o.
</div>
