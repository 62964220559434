<af-page-bar title="{{ 'hollen.clientapp.src.app.account.settings.event-subscriptions.component.notification subscriptions' | translate }}" helpid="settings-subscribtion.guide"></af-page-bar>

<af-page-filter [loading]="isLoading" (filter)="search()" (reset)="reset()" (clear)="clear()">
    <div class="form-group">
        <input type="text" class="form-control me-sm-1" [(ngModel)]="searchTerm" placeholder="{{ 'filter.search' | translate }}" />
    </div>
</af-page-filter>

<div class="form">
    <form (ngSubmit)="update()" name="Form" novalidate="" #Form="ngForm" [class.disabled-all]="isLoading">
        <div class="form-horizontal">
            <div class="form-body" style="padding: 0px;">
                <div class="row">
                    <div class="col-md-12">
                        <af-note type="info" *ngIf="groups && groups.length === 0">
                            {{ 'hollen.clientapp.src.app.account.settings.event-subscriptions.component.no records found' | translate }}
                        </af-note>
                        <table class="af-table table table-striped table-bordered table-hover" *ngIf="groups && groups.length > 0">
                            <tbody>
                                <ng-container *ngFor="let group of groups; let $groupIndex = index">
                                    <tr class="table-secondary">
                                        <td style="width: 50%" colspan="2">
                                            <div>
                                                <b>{{ group.name }}</b>
                                                <span *ngIf="group.isForAllUsers"> ({{ 'event.notificationtype.all active users' | translate }})</span>
                                            </div>
                                            <div>{{group.systemDescription}}</div>
                                        </td>
                                    </tr>
                                    <ng-container>
                                        <tr *ngFor="let item of group.subscriptions; let $index = index" [class.table-warning]="!item.isAllowed">
                                            <td align="right" style="width: 50%">
                                                <div>
                                                    <i class="fa fa-exclamation-triangle" *ngIf="!item.isAllowed" style="margin-right: 3px;"></i>
                                                    {{ item.description }}
                                                </div>
                                            </td>
                                            <td>
                                                <af-checkbox [name]="'isEmail' + $groupIndex + '_' + $index" *ngIf="event.isEmailEnabled"
                                                             [disabled]="!item.isEmailAvailable || item.isForAllUsers"
                                                             [(ngModel)]="item.isEmail">
                                                    {{ 'event.notificationtype.email' | translate }}
                                                </af-checkbox>
                                                <af-checkbox [name]="'isNotification' + $groupIndex + '_' + $index" *ngIf="event.isNotificationEnabled"
                                                             [disabled]="item.isForAllUsers"
                                                             [(ngModel)]="item.isNotification">
                                                    {{ 'event.notificationtype.notification' | translate }}
                                                </af-checkbox>
                                                <af-checkbox [name]="'isSms' + $groupIndex + '_' + $index" *ngIf="event.isSmsEnabled"
                                                             [disabled]="item.isForAllUsers"
                                                             [(ngModel)]="item.isSms">
                                                    {{ 'event.notificationtype.sms' | translate }}
                                                </af-checkbox>
                                            </td>
                                        </tr>
                                    </ng-container>
                                </ng-container>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
        <div class="form-actions">
            <div class="row">
                <div class="col-md-12">
                    <div class="form-group">
                        <div class="offset-md-1 col-md-11">
                            <button *ngIf="model && model.subscriptions && model.subscriptions.length > 0" class="btn btn-update btn-primary" type="submit">{{ 'btn.save' | translate }}</button>
                            <a class="btn-back btn-warning btn" onclick="history.back()">{{ 'btn.back' | translate }}</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>
