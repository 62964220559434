
import { filter } from "rxjs/operators";
import { Component, OnInit, OnDestroy } from "@angular/core";
import { Router } from "@angular/router";
import { Subscription } from "rxjs";
import { Store } from "@ngrx/store";

import { AppService } from "../app.service";
import { PermissionService } from "../core/index";
import { State } from "../store/reducers";
import { AuthConfig } from "./auth.config";

@Component({
    template: ``
})
export class RedirectComponent implements OnInit, OnDestroy {
    sub: Subscription;

    constructor(private store: Store<State>, private authConfig: AuthConfig, private permissionService: PermissionService, private router: Router) { }

    ngOnInit() {
        this.sub = this.store
            .select(x => x.app.homeViewModel).pipe(
                filter(loaded => !!loaded))
            .subscribe(data => {
                let redirectUrl = this.authConfig.defaultUrl;

                if (data && data.homeUrl) {
                    redirectUrl = data.homeUrl;
                } else {
                    if (!this.permissionService.canRead("op.project")) {
                        redirectUrl = "/account";
                    }
                }

                this.router.navigateByUrl(redirectUrl);
            });
    }

    ngOnDestroy() {
        if (this.sub) {
            this.sub.unsubscribe();
        }
    }
}
