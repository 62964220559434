<ng-container *ngIf="userNotExists">
    <af-note type="info">
        {{ 'hollen.clientapp.src.app.account.settings.home-url.component.user not exists in this database' | translate }}
    </af-note>
</ng-container>

<ng-container *ngIf="!userNotExists && showMenu">
    <div class="form">
        <form (ngSubmit)="update()" name="editSettingsForm" novalidate="" #editSettingsForm="ngForm" [class.disabled-all]="isLoading">
            <div class="form-horizontal">
                <div class="form-body">
                    <ul>
                        <li *ngFor="let item of menu">
                            <acc-home-url-tree [item]="item" [selected]="selected" (selectedChanged)="onChange($event)"></acc-home-url-tree>
                        </li>
                    </ul>
                </div>
                <div class="form-actions">
                    <div class="row">
                        <div class="col-md-12">
                            <div class="form-group">
                                <div class="offset-md-1 col-md-11">
                                    <button class="btn btn-insert btn-primary" type="submit">{{ 'btn.save' | translate }}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</ng-container>