import { NgModule } from "@angular/core";

import { SharedModule } from "../shared/shared.module";
import { AccountRoutingModule, accountRoutedComponents } from "./account-routing.module";
import { HomeUrlTreeComponent } from "./settings/home-url-tree.component";

@NgModule({
    imports: [
        SharedModule,
        AccountRoutingModule
    ],
    declarations: [
        HomeUrlTreeComponent,
        accountRoutedComponents
    ],
    providers: []
})
export class AccountModule { }
