import { Component, OnInit, OnDestroy } from "@angular/core";
import { NgForm } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { Store } from "@ngrx/store";

import { AuthService } from "./auth.service";
import { AppService } from "../app.service";
import { State } from "../store/reducers";
import * as appActions from "../store/actions/app";
import { Subscription } from "rxjs";

@Component({
    selector: "af-login",
    templateUrl: "./login.component.html"
})
export class LoginComponent implements OnInit, OnDestroy {
    isLoading = false;
    loginData: af.usersystem.LoginViewModel;
    message: string | null;
    languageId: string;
    returnUrl: string;
    querySub: any;
    sub: Subscription;

    constructor(private authService: AuthService,
        private appService: AppService,
        private store: Store<State>,
        private route: ActivatedRoute,
        private router: Router) { }

    ngOnInit() {
        this.querySub = this.route.queryParams.subscribe(params => {
            this.returnUrl = params["returnUrl"] || "/";
            if (params["culture"]) {
                this.languageId = params["culture"];
            }
        });

        this.sub = this.store.select(x => x.app.language).subscribe(language => {
            this.languageId = language;
        });

        this.loginData = {
            userName: null,
            password: null
        };
    }

    login(loginForm: NgForm) {
        if (!loginForm.valid) {
            return;
        }

        this.isLoading = true;
        this.message = null;

        this.authService.login(this.loginData).subscribe(
            response => {
                this.authService.save(response);
                this.router.navigateByUrl(this.returnUrl).then(res => {
                    window.location.reload();
                });
            },
            response => {
                this.authService.logout(false);
                this.isLoading = false;
                this.message = "invalid_login";
            });
    }

    changeLanguage(language: string) {
        this.store.dispatch(new appActions.LanguageChangedAction(language));
    }

    ngOnDestroy() {
        if (this.querySub != null) {
            this.querySub.unsubscribe();
        }
        if (this.sub) {
            this.sub.unsubscribe();
        }
    }
}
