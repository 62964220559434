import { Component, OnInit } from "@angular/core";
import { NgForm } from "@angular/forms";

import { AuthService } from "./auth.service";

@Component({
    selector: "af-forgot-password",
    templateUrl: "./forgot-password.component.html"
})

export class ForgotPasswordComponent implements OnInit {
    isSuccess = false;
    isLoading = false;
    isError = false;
    message: string;
    forgotPasswordData: af.usersystem.ForgotPasswordViewModel;

    constructor(private authService: AuthService) {

    }

    ngOnInit() {
        this.forgotPasswordData = {
            email: null
        };
    }

    send(forgotPasswordForm: NgForm) {
        if (!forgotPasswordForm.valid) {
            return;
        }

        this.isLoading = true;
        this.authService.forgotPassword(this.forgotPasswordData).subscribe((response) => {
            this.isSuccess = true;
            this.forgotPasswordData.email = null;
            this.message = response.message;
            if (this.message.includes("duplicit")) {
                this.isError = true;
            }
            forgotPasswordForm.reset();  
        },
            (response) => {
                this.isSuccess = false;
                this.message = response.message || "Error";
            }, () => {
                this.isLoading = false;
            });
    }
}
