import { NgModule, Component } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";

import { SettingsComponent } from "./settings/settings.component";
import { EventSubscriptionsComponent } from "./settings/event-subscriptions.component";
import { HomeUrlComponent } from "./settings/home-url.component";
import { UnsavedChangesGuard, HomeResolver } from "../core";
import { AuthGuard } from "../auth/auth-guard.service";
import { EmployeeWorkClothesComponent } from "../operation/employee/employee-work-clothes.component";
import { EmployeeInventoryComponent } from "../operation/employee/employee-inventory.component";
import { FilesComponent } from './settings/files.component';

@Component({
    selector: "acc-settings-tab",
    template: "<af-tabs></af-tabs><router-outlet></router-outlet>"
})
export class SettingsTabComponent { }

const routes: Routes = [
    {
        path: "account",
        resolve: { home: HomeResolver },
        canActivate: [AuthGuard],
        children: [
            {
                path: "",
                redirectTo: "settings",
                pathMatch: "full"
            },
            {
                path: "settings",
                component: SettingsTabComponent,
                children: [
                    {
                        path: "subscriptions",
                        canDeactivate: [UnsavedChangesGuard],
                        component: EventSubscriptionsComponent,
                        data: {
                            isMetriq: false
                        }
                    },
                    {
                        path: "metriq-subscriptions",
                        canDeactivate: [UnsavedChangesGuard],
                        component: EventSubscriptionsComponent,
                        data: {
                            isMetriq: true
                        }
                    },
                    {
                        path: "home-url",
                        canDeactivate: [UnsavedChangesGuard],
                        component: HomeUrlComponent
                    },
                    {
                        path: "work-clothes",
                        canDeactivate: [UnsavedChangesGuard],
                        component: EmployeeWorkClothesComponent,
                        data: {
                            currentUser: true
                        }
                    },
                    {
                        path: "inventory",
                        canDeactivate: [UnsavedChangesGuard],
                        component: EmployeeInventoryComponent,
                        data: {
                            currentUser: true
                        }
                    },
                    {
                        path: "files",
                        component: FilesComponent,
                        data: {
                            currentUser: true
                        }
                    },
                    {
                        path: "",
                        canDeactivate: [UnsavedChangesGuard],
                        component: SettingsComponent
                    }
                ]
            }
        ]
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class AccountRoutingModule { }

export const accountRoutedComponents = [
    SettingsComponent,
    EventSubscriptionsComponent,
    SettingsTabComponent,
    HomeUrlComponent,
    FilesComponent
];
