import "./app/vendor";
import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import * as Sentry from "@sentry/angular";

if (environment.production)
{
    Sentry.init({
        dsn: "https://be81dfc18d44072121c1da7703d6458b@o4507503253192704.ingest.de.sentry.io/4507583581126736",
        tunnel: "/tunnel",
        // integrations: [
        //     Sentry.feedbackIntegration({
        //         // Additional SDK configuration goes in here, for example:
        //         colorScheme: "system",
        //         showBranding: false
        //     }),
        // ],
    });

    enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
    .catch(err => console.error(err));
