import * as menu from "../actions/menu";
import { MenuItem } from "../../models/menu-item";

export interface State {
    menu: MenuItem[];
}

const initialState: State = {
    menu: null
};

export function reducer(state = initialState, action: menu.Actions): State {
    switch (action.type) {
        case menu.ActionTypes.MENU_TOGGLED:
            return Object.assign({}, state, {
                menu: [...action.payload]
            });
        case menu.ActionTypes.MENU_UPDATED:
            return Object.assign({}, state, {
                menu: [...action.payload]
            });
        default:
            return state;
    }
}
