import { RouterStateSnapshot } from "@angular/router";

import * as actions from "../actions/app";
import * as Sentry from "@sentry/angular";

export interface State {
    newVersionAvailable: boolean;
    isAuthenticated: boolean;
    homeViewModel: app.HomeViewModel;
    loginViewModel: app.LoginViewModel;
    language: string;
    routeChanged: RouterStateSnapshot;
    isLoading: boolean;
    theme: string;
}

const initialState: State = {
    newVersionAvailable: false,
    isAuthenticated: false,
    homeViewModel: null,
    loginViewModel: null,
    language: "sk",
    routeChanged: null,
    isLoading: false,
    theme: "light"
};

export function reducer(state = initialState, action: actions.Actions): State {
    switch (action.type) {
        case actions.ActionTypes.NEW_VERSION_AVAILABLE:
            return Object.assign({}, state, {
                newVersionAvailable: true
            });
        case actions.ActionTypes.USER_AUTHENTICATED:
            return Object.assign({}, state, {
                isAuthenticated: (<actions.UserAuthenticatedAction>action).payload
            });
        case actions.ActionTypes.HOMEVIEWMODEL_LOADED:
            const home = (<actions.HomeViewModelLoadedAction>action).payload;
            if (home && home.user && home.user.userName) {
                Sentry.setUser({ id: home.user.userId, username: home.user.userName });
            }
            else {
                Sentry.setUser(null);
            }
            return Object.assign({}, state, {
                homeViewModel: home,
                theme: home.theme
            });
        case actions.ActionTypes.LOGINVIEWMODEL_LOADED:
            return Object.assign({}, state, {
                loginViewModel: (<actions.LoginViewModelLoadedAction>action).payload
            });
        case actions.ActionTypes.LANGUAGE_CHANGED:
            return Object.assign({}, state, {
                language: (<actions.LanguageChangedAction>action).payload
            });
        case actions.ActionTypes.ROUTE_CHANGED:
            return Object.assign({}, state, {
                routeChanged: (<actions.RouteChangedAction>action).payload
            });
        case actions.ActionTypes.LOADING:
            return Object.assign({}, state, {
                isLoading: (<actions.LoadingAction>action).payload
            });
        case actions.ActionTypes.THEME_CHANGED:
            return Object.assign({}, state, {
                theme: (<actions.ThemeChangedAction>action).payload
            });
        default:
            return state;
    }
}
