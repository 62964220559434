import { finalize, filter } from "rxjs/operators";
import { Component, ViewChild, OnInit } from "@angular/core";
import { NgForm } from "@angular/forms";
import { Router, ActivatedRoute } from "@angular/router";

import { EventService } from "../../sys/event/event.service";
import { FormComponent } from "../../core";
import { Store } from "@ngrx/store";
import { State } from "../../store/reducers";

class EventGroup {
    name: string;
    eventType: number;
    systemDescription: string;
    subscriptions: af.sys.EventSubscriptionViewModel[];
    isForAllUsers: boolean;
}

@Component({
    selector: "acc-event-subscriptions",
    templateUrl: "./event-subscriptions.component.html"
})
export class EventSubscriptionsComponent extends FormComponent implements OnInit {
    @ViewChild(NgForm, { static: true }) form: NgForm;
    model: af.sys.EventUserViewModel;
    objectKey: string;
    userId: number;
    event = <app.EventHomeViewModel>{};
    groups: EventGroup[];
    searchTerm: string;
    isMetriq = false;

    constructor(
        protected router: Router,
        private route: ActivatedRoute,
        private eventService: EventService,
        private store: Store<State>
    ) {
        super(router);
        this.objectKey = eventService.objectKey;
    }

    ngOnInit() {
        this.isLoading = true;

        this.isMetriq = this.route.snapshot.data.isMetriq;

        this.store
            .select(x => x.app.homeViewModel).pipe(
                filter(loaded => !!loaded))
            .subscribe(model => {
                this.event = model.event;
            });

        let request = this.isMetriq ? this.eventService.getMetriqByCurrentUser() : this.eventService.getByCurrentUser();

        request.subscribe(res => {
            this.model = res;
            this.groupBy(res.subscriptions);
            this.isLoading = false;
        });
    }

    update() {
        this.isLoading = true;

        let items = this.model.subscriptions.filter(x => x.isEmail || x.isNotification || x.isSms);

        let request = this.isMetriq ? this.eventService.updateMetriqCurrentUserSubscriptions(items) : this.eventService.updateCurrentUserSubscriptions(items);

        request.pipe(finalize(() => (this.isLoading = false))).subscribe();
    }

    search() {
        let subscriptions = this.model.subscriptions;
        if (this.searchTerm && this.searchTerm.length > 1) {
            let lsearchTerm = this.searchTerm.toLowerCase();
            subscriptions = this.model.subscriptions.filter((a) =>
                a.name.toLowerCase().includes(lsearchTerm) || (a.description && a.description.toLowerCase().includes(lsearchTerm)) || a.systemDescription.toLowerCase().includes(lsearchTerm)
            );
        }
        this.groupBy(subscriptions);
    }

    groupBy(subscriptions: af.sys.EventSubscriptionViewModel[]) {
        this.groups = subscriptions.reduce((group: EventGroup[], sub: af.sys.EventSubscriptionViewModel) => {
            let g = group.find(x => x.eventType === sub.eventType);
            if (g) {
                g.subscriptions.push(sub);
            }
            else {
                g = {
                    eventType: sub.eventType,                   
                    name: sub.name,
                    systemDescription: sub.systemDescription,
                    subscriptions: [sub],
                    isForAllUsers: sub.isForAllUsers
                }
                group.push(g);
            }
            return group;
        }, []);
    }

    reset() {
        this.clear();
    }

    clear() {
        this.searchTerm = null;
    }
}
