<form class="login-form" (ngSubmit)="login(loginForm)" #loginForm="ngForm" novalidate autocomplete="off">
    <div class="form-title">{{ 'hollen.clientapp.src.app.auth.login.component.welcome.' | translate }}</div>
    <div class="form-subtitle">{{ 'hollen.clientapp.src.app.auth.login.component.enter your credentials.' | translate }}</div>
    <div class="alert alert-danger" *ngIf="!loginForm.valid && loginForm.submitted">
        <button class="close" data-close="alert"></button>
        <span>
            {{ 'hollen.clientapp.src.app.auth.login.component.enter username and password.' | translate }}
        </span>
    </div>
    <div class="alert alert-danger" *ngIf="message">
        <span *ngIf="message === 'invalid_login'">
            {{ 'hollen.clientapp.src.app.auth.login.component.invalid username or password' | translate }}
        </span>
        <span *ngIf="message !== 'invalid_login'">
            {{ message }}
        </span>
    </div>
    <div class="mb-3">
        <input class="form-control form-control-solid placeholder-no-fix" type="text" autocomplete="off" placeholder="{{ 'hollen.clientapp.src.app.auth.login.component.username' | translate }}" name="userName" [(ngModel)]="loginData.userName" required />
    </div>
    <div class="mb-3">
        <input class="form-control form-control-solid placeholder-no-fix" type="password" autocomplete="off" placeholder="{{ 'hollen.clientapp.src.app.auth.login.component.password' | translate }}" name="password" [(ngModel)]="loginData.password" required />
    </div>
    <div class="form-actions">
        <button type="submit" class="btn btn-primary btn-block uppercase w-100" [disabled]="isLoading" (dblclick)="0">{{ 'hollen.clientapp.src.app.auth.login.component.login' | translate }}</button>
    </div>
    <div class="language-actions">
        <div class="la-left">
            {{ 'hollen.clientapp.src.app.auth.login.component.choose language_' | translate }}
            <ul class="languages">
                <li>
                    <a (click)="changeLanguage('en')" [class.disabled]="languageId === 'en'">English</a>
                </li>
                <li>
                    <a (click)="changeLanguage('sk')" [class.disabled]="languageId === 'sk'">Slovenčina</a>
                </li>
            </ul>
        </div>
        <div class="la-filler"></div>
        <div class="la-right">
            <a routerLink="/forgot-password" class="forget-password">{{ 'hollen.clientapp.src.app.auth.login.component.forgot password?' | translate }}</a>
        </div>
    </div>
</form>
