import { OnInit, OnDestroy, Component, ElementRef, HostListener, Renderer2, Inject } from "@angular/core";
import { Store } from "@ngrx/store";
import { Observable, Subscription } from "rxjs";

import { AuthService } from "./auth/auth.service";
import { AppService } from "./app.service";
import { State } from "./store/reducers";
import * as appActions from "./store/actions/app";
import { DOCUMENT } from "@angular/common";

@Component({
    selector: "af-hollen-app",
    styleUrls: ["./app.component.scss"],
    templateUrl: "./app.component.html"
})
export class AppComponent implements OnInit, OnDestroy {
    isNewVersion$: Observable<boolean>;
    isAuthenticated: boolean;
    className = "login";
    subscriptions: Subscription[] = [];

    constructor(
        private elementRef: ElementRef,
        private authService: AuthService,
        private appService: AppService, //important to initialize
        @Inject(DOCUMENT) private document: Document,
        private renderer: Renderer2,
        private store: Store<State>) {
        this.isNewVersion$ = store.select(x => x.app.newVersionAvailable);
        this.store.dispatch(new appActions.UserAuthenticatedAction(authService.isAuthenticated));
    }

    ngOnInit() {
        this.subscriptions.push(this.store.select(x => x.app.isAuthenticated).subscribe((isAuthenticated: boolean) => {
            this.isAuthenticated = isAuthenticated;
        }));

        this.subscriptions.push(this.store.select(x => x.app.homeViewModel).subscribe((homeViewModel: app.HomeViewModel) => {
            if (homeViewModel) {
                this.loadGoogleAnalytics(homeViewModel.googleAnalyticsId, homeViewModel.user.userName);
            }
        }));
    }

    ngOnDestroy() {
        this.subscriptions.forEach(s => s.unsubscribe());
    }

    toggleBodyClass(isAuthenticated: boolean) {
        let bodyElement = this.elementRef.nativeElement.parentElement;

        if (!bodyElement) {
            return;
        }

        if (isAuthenticated) {
            bodyElement.classList.remove(this.className);
        } else {
            bodyElement.classList.add(this.className);
        }
    }

    @HostListener("window:scroll", [])
    onWindowScroll() {
        this.scrollFunction();
    }

    // When the user scrolls down 20px from the top of the document, show the button
    scrollFunction() {
        if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
            document.getElementById("scrollTopButton").style.display = "block";
        } else {
            document.getElementById("scrollTopButton").style.display = "none";
        }
    }

    // When the user clicks on the button, scroll to the top of the document
    topFunction() {
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    }

    loadGoogleAnalytics(id: string, username: string) {
        const script = this.renderer.createElement('script');
        script.src = `https://www.googletagmanager.com/gtag/js?id=${id}`;
        script.onload = () => {
            const script = this.renderer.createElement('script');
            script.innerText = `window.dataLayer = window.dataLayer || [];function gtag(){dataLayer.push(arguments);}gtag(\'js\', new Date()); gtag(\'config\', \'${id}\', { \'user_id\': \'${username}\' });`;
            this.renderer.appendChild(this.document.body, script);
        };
        this.renderer.appendChild(this.document.body, script);
    }
}
