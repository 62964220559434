import * as layout from "../actions/layout";

export interface State {
    sidebarIsClosed: boolean;
    mobileMenuIsCollapsed: boolean;
}

const initialState: State = {
    sidebarIsClosed: false,
    mobileMenuIsCollapsed: true
};

export function reducer(state = initialState, action: layout.Actions): State {
    switch (action.type) {
        case layout.ActionTypes.CLOSE_SIDENAV:
            return Object.assign({}, state, {
                sidebarIsClosed: true
            });
        case layout.ActionTypes.OPEN_SIDENAV:
            return Object.assign({}, state, {
                sidebarIsClosed: false
            });
        case layout.ActionTypes.OPEN_MOBILE_MENU:
            return Object.assign({}, state, {
                mobileMenuIsCollapsed: false
            });
        case layout.ActionTypes.CLOSE_MOBILE_MENU:
            return Object.assign({}, state, {
                mobileMenuIsCollapsed: true
            });

        default:
            return state;
    }
}
