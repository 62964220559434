
<af-page-bar title="{{ 'hollen.clientapp.src.app.account.settings.settings.component.my account' | translate }}"></af-page-bar>

<div class="form">
    <form (ngSubmit)="update()" name="editSettingsForm" novalidate="" #editSettingsForm="ngForm" [class.disabled-all]="isLoading">
        <div class="form-horizontal">
            <div class="form-body">
                <div class="row">
                    <div class="col-md-6">
                        <div class="form-group">
                            <label class="control-label col-md-2" for="LanguageId">{{ 'hollen.usersystem.accountsettingviewmodel.language' | translate }}</label>
                            <div class="col-md-10">
                                <select class="form-control form-select" [(ngModel)]="settings.languageId" id="LanguageId" maxlength="2" name="LanguageId">
                                    <option *ngFor="let language of languages" [ngValue]="language.key">{{language.value}}</option>
                                </select>
                                <af-help helpid="Hollen.UserSystem.AccountSettingViewModel.LanguageId"></af-help>
                                <span><span *ngIf="(editSettingsForm.controls['LanguageId']?.dirty || editSettingsForm.submitted) && editSettingsForm.controls['LanguageId']?.hasError('maxlength')" class="field-validation-error">{{ 'validator.the field {0} must be a string or array type with a maximum  length of \'{1}\'.' | translate:{'0': 'translate|hollen.usersystem.accountsettingviewmodel.language', '1': '2'} }}</span>
</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6">
                        <div class="form-group">
                            <label class="control-label col-md-2" for="Theme">{{ 'hollen.usersystem.accountsettingviewmodel.theme' | translate }}</label>
                            <div class="col-md-10">
                                <select class="form-control form-select" [(ngModel)]="settings.theme" id="Theme" name="Theme">
                                    <option [ngValue]="'light'">{{ 'hollen.clientapp.src.app.account.settings.settings.component.light mode' | translate }}</option>
                                    <option [ngValue]="'dark'">{{ 'hollen.clientapp.src.app.account.settings.settings.component.dark mode' | translate }}</option>
                                </select>
                                <af-help helpid="Hollen.UserSystem.AccountSettingViewModel.Theme"></af-help>
                                <span></span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6">
                        <div class="form-group">
                            <label class="control-label col-md-2" for="Signature">{{ 'hollen.usersystem.accountsettingviewmodel.signature' | translate }}</label>
                            <div class="col-md-10">
                                 <div>
                                    <label class="custom-file-upload btn btn-success">
                                        {{ 'hollen.clientapp.src.app.account.settings.settings.component.upload signature' | translate }}
                                        <input type="file" (change)="fileChangeEvent($event)" class="fileInputProfile" accept="image/png, image/jpeg">
                                    </label>
                                    <a class="btn btn-danger" (click)="removeImage()" *ngIf="settings.signature">{{ 'hollen.clientapp.src.app.account.settings.settings.component.delete' | translate }}</a>
                                    <div class="mt-2">
                                        <ng-container *ngIf="settings.signature">
                                            <img [src]="settings.signature" height="200" />
                                        </ng-container>
                                    </div>
                                </div>
                                <span class="field-validation-error" *ngIf="imageError">{{ imageError }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="form-actions">
                <div class="row">
                    <div class="col-md-12">
                        <div class="form-group">
                            <div class="offset-md-1 col-md-11">
                                <button class="btn btn-insert btn-primary" type="submit">{{ 'btn.save' | translate }}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>

<h3 class="page-bar">
    {{ 'hollen.clientapp.src.app.account.settings.settings.component.change password' | translate }}
</h3>


<div class="form">
    <form (ngSubmit)="changePassword()" name="changePasswordForm" novalidate="" #changePasswordForm="ngForm" [class.disabled-all]="isLoading">
        <div class="form-horizontal">
            <div class="form-body">
                <div class="row">
                    <div class="col-md-6">
                        <div class="form-group">
                            <label class="control-label col-md-2" for="OldPassword">{{ 'alejframe.usersystem.changepasswordviewmodel.current password' | translate }}</label>
                            <div class="col-md-10">
                                <input class="form-control" [(ngModel)]="changePasswordModel.oldPassword" type="password" id="OldPassword" name="OldPassword" required="" />
                                <af-help helpid="AlejFrame.UserSystem.ChangePasswordViewModel.OldPassword"></af-help>
                                <span><span *ngIf="(changePasswordForm.controls['OldPassword']?.dirty || changePasswordForm.submitted) && changePasswordForm.controls['OldPassword']?.hasError('required')" class="field-validation-error">{{ 'validator.{0} is required.' | translate:{'0': 'translate|alejframe.usersystem.changepasswordviewmodel.current password'} }}</span>
<span *ngIf="(changePasswordForm.controls['OldPassword']?.dirty || changePasswordForm.submitted) && changePasswordForm.controls['OldPassword']?.hasError('generic')" class="field-validation-error">{{ 'validator.the {0} field is invalid.' | translate:{'0': 'translate|alejframe.usersystem.changepasswordviewmodel.current password'} }}</span>
</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6">
                        <div class="form-group">
                            <label class="control-label col-md-2" for="NewPassword">{{ 'alejframe.usersystem.changepasswordviewmodel.new password' | translate }}</label>
                            <div class="col-md-10">
                                <input class="form-control" [(ngModel)]="changePasswordModel.newPassword" type="password" id="NewPassword" name="NewPassword" required="" />
                                <af-help helpid="AlejFrame.UserSystem.ChangePasswordViewModel.NewPassword"></af-help>
                                <span><span *ngIf="(changePasswordForm.controls['NewPassword']?.dirty || changePasswordForm.submitted) && changePasswordForm.controls['NewPassword']?.hasError('required')" class="field-validation-error">{{ 'validator.{0} is required.' | translate:{'0': 'translate|alejframe.usersystem.changepasswordviewmodel.new password'} }}</span>
<span *ngIf="(changePasswordForm.controls['NewPassword']?.dirty || changePasswordForm.submitted) && changePasswordForm.controls['NewPassword']?.hasError('generic')" class="field-validation-error">{{ 'validator.the {0} field is invalid.' | translate:{'0': 'translate|alejframe.usersystem.changepasswordviewmodel.new password'} }}</span>
</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6">
                        <div class="form-group">
                            <label class="control-label col-md-2" for="ConfirmPassword">{{ 'alejframe.usersystem.changepasswordviewmodel.confirm new password' | translate }}</label>
                            <div class="col-md-10">
                                <input class="form-control" [(ngModel)]="changePasswordModel.confirmPassword" validateEqual="NewPassword" type="password" id="ConfirmPassword" name="ConfirmPassword" />
                                <af-help helpid="AlejFrame.UserSystem.ChangePasswordViewModel.ConfirmPassword"></af-help>
                                <span class="field-validation-error" *ngIf="changePasswordForm.controls.ConfirmPassword?.hasError('validateEqual')">
                                    {{ 'hollen.clientapp.src.app.account.settings.change-password.component.password not match' | translate }}
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="form-actions">
                <div class="row">
                    <div class="col-md-12">
                        <div class="form-group">
                            <div class="offset-md-1 col-md-11">
                                <button class="btn btn-insert btn-primary" type="submit">{{ 'hollen.clientapp.src.app.account.settings.change-password.component.change password' | translate }}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </form>
</div>


