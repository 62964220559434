import { NgbTypeaheadConfig } from '@ng-bootstrap/ng-bootstrap';

export function ngbTypeaheadConfigFactory(): NgbTypeaheadConfig {
    const config = new NgbTypeaheadConfig();
    config.placement = ['bottom-left', 'bottom', 'bottom-right','left','top-left','top','top-right'];
    return config;
}

export const ngbTypeaheadConfigProvider = {
    provide: NgbTypeaheadConfig,
    useFactory: ngbTypeaheadConfigFactory
};
