    import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";

import { PageNotFoundComponent } from "./page-not-found.component";
import { AuthGuard } from "./auth/auth-guard.service";
import { HomeResolver, PreloadSelectedModulesList } from "./core";

const shouldPreload = false; //!alejFrame || !alejFrame.debug;

export const routes: Routes = [
    {
        path: "organization",
        loadChildren: () => import("./organization/organization.module").then(m => m.OrganizationModule),
        data: { preload: shouldPreload },
        resolve: { home: HomeResolver },
        canActivate: [AuthGuard]
    },
    {
        path: "finance",
        loadChildren: () => import("./finance/finance.module").then(m => m.FinanceModule),
        data: { preload: shouldPreload },
        resolve: { home: HomeResolver },
        canActivate: [AuthGuard]
    },
    {
        path: "crm",
        loadChildren: () => import("./crm/crm.module").then(m => m.CrmModule),
        data: { preload: shouldPreload },
        resolve: { home: HomeResolver },
        canActivate: [AuthGuard]
    },
    {
        path: "operation",
        loadChildren: () => import("./operation/operation.module").then(m => m.OperationModule),
        data: { preload: shouldPreload },
        resolve: { home: HomeResolver },
        canActivate: [AuthGuard]
    },
    {
        path: "codebook",
        loadChildren: () => import("./codebook/codebook.module").then(m => m.CodebookModule),
        data: { preload: shouldPreload },
        resolve: { home: HomeResolver },
        canActivate: [AuthGuard]
    },
    {
        path: "report",
        loadChildren: () => import("./report/report.module").then(m => m.ReportModule),
        data: { preload: shouldPreload },
        resolve: { home: HomeResolver },
        canActivate: [AuthGuard]
    },
    {
        path: "sys",
        loadChildren: () => import("./sys/sys.module").then(m => m.SysModule),
        data: { preload: false },
        resolve: { home: HomeResolver },
        canActivate: [AuthGuard]
    },
    {
        path: "usersystem",
        loadChildren: () => import("./usersystem/usersystem.module").then(m => m.UserSystemModule),
        data: { preload: false },
        resolve: { home: HomeResolver },
        canActivate: [AuthGuard]
    },
    {
        path: "logistic",
        loadChildren: () => import("./logistic/logistic.module").then(m => m.LogisticModule),
        data: { preload: false },
        resolve: { home: HomeResolver },
        canActivate: [AuthGuard]
    },
    {
        path: "dashboard",
        loadChildren: () => import("./dashboard/dashboard.module").then(m => m.DashboardModule),
        data: { preload: false },
        resolve: { home: HomeResolver },
        canActivate: [AuthGuard]
    },
    {
        path: "metriq-sync",
        loadChildren: () => import("./metriq-sync/metriq-sync.module").then(m => m.MetriqSyncModule),
        data: { preload: shouldPreload },
        resolve: { home: HomeResolver },
        canActivate: [AuthGuard]
    },
    {
        path: "**",
        pathMatch: "full",
        component: PageNotFoundComponent,
        resolve: { home: HomeResolver },
        canActivate: [AuthGuard]
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes,
        { preloadingStrategy: PreloadSelectedModulesList, enableTracing: false }
    )],
    providers: [
        PreloadSelectedModulesList
    ],
    exports: [RouterModule]
})
export class AppRoutingModule { }
