import { Component, OnInit, OnDestroy } from "@angular/core";
import { NgForm } from "@angular/forms";
import { Router } from "@angular/router";

import { AuthService } from "./auth.service";

@Component({
    selector: "af-reset-password",
    templateUrl: "./reset-password.component.html"
})
export class ResetPasswordComponent implements OnInit, OnDestroy {
    querySub: any;
    isLoading = false;
    message: string;
    resetPasswordData: af.usersystem.ResetPasswordViewModel;

    constructor(private authService: AuthService, private router: Router) {

    }

    ngOnInit() {
        this.resetPasswordData = {
            code: null,
            email: null,
            password: null,
            confirmPassword: null
        };

        this.querySub = this.router.routerState.root.queryParams.subscribe(params => {
            this.resetPasswordData.code = decodeURIComponent(params["code"]);
        });
    }

    ngOnDestroy() {
        if (this.querySub != null) {
            this.querySub.unsubscribe();
        }
    }

    resetPassword(resetPasswordForm: NgForm) {
        if (!resetPasswordForm.valid) {
            return;
        }

        if (this.resetPasswordData.password !== this.resetPasswordData.confirmPassword) {
            return;
        }

        this.isLoading = true;
        this.authService.resetPassword(this.resetPasswordData).subscribe((response) => {
            this.message = response.message;
            this.router.navigate(["/login"]);
        },
            (response) => {
                this.message = response.message || "Error";
                this.isLoading = false;
            }, () => {
                this.isLoading = false;
            });
    }
}
