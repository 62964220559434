import { NgModule } from "@angular/core";

import { SharedModule } from "../shared/shared.module";
import { AuthRoutingModule, routedComponents } from "./auth-routing.module";
import { AuthResolver } from "./auth-resolver.service";
import { AuthGuard } from "./auth-guard.service";
import { AuthConfig } from "./auth.config";
import { StorageService } from "./storage.service";
import { AuthService } from "./auth.service";

@NgModule({
    imports: [
        SharedModule,
        AuthRoutingModule
    ],
    declarations: [
        routedComponents
    ],
    providers: [
        AuthResolver,
        AuthGuard,
        AuthConfig,
        StorageService,
        AuthService
    ]
})
export class AuthModule { }
