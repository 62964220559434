import { Component } from "@angular/core";

@Component({
    selector: "af-auth",
    styleUrls: ["./auth.component.scss"],
    templateUrl: "./auth.component.html"
})
export class AuthComponent {
    protected year = new Date().getFullYear();
}
