import { Component, Input, Output, EventEmitter } from "@angular/core";
import { MenuItem } from "../../models/menu-item";

@Component({
    selector: "acc-home-url-tree",
    template: `
        <a class="item-{{item.type}}" [ngClass]="{'item-selected': selected === item.url, 'item-disabled': item.hasSubMenu}" [href]="item.url" (click)="onSelected($event, item)">
            {{item.title }}
        </a>
        <ul *ngIf="item.hasSubMenu">
            <ng-container *ngFor="let child of item.children">
                <li *ngIf="child.type === 'Menu'">
                    <acc-home-url-tree [item]="child" [selected]="selected" (selectedChanged)="onChange($event)"></acc-home-url-tree>
                </li>
            </ng-container>
        </ul>
    `,
    styles: [`
        .item-Menu {
            color: black;
        }
        .item-selected {
            background-color: #5b9bd1;
            color: white;
            padding: 2px 4px;
        }
        .item-disabled {
            color: #867a7a;
            pointer-events: none;
        }
    `]
})
export class HomeUrlTreeComponent {
    @Input() item: any;
    @Input() selected: string;
    @Output() selectedChanged = new EventEmitter<string>();

    onSelected(event: any, item: MenuItem) {
        event.preventDefault();

        if (item.hasSubMenu) {
            return;
        }

        this.selectedChanged.emit(item.url);
    }

    onChange(url: string) {
        this.selectedChanged.emit(url);
    }
}
